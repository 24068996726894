
import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { UserRightsMixin } from '@/mixins/UserRights';
import moment from 'moment';
import { Moment } from 'moment';

@Component({
    name: 'ActiveStatus',
})
export default class ActiveStatus extends mixins<UserRightsMixin>(UserRightsMixin) {
    @Prop({ default: '' }) private manufactureDate!: Moment | string;
    @Prop({ default: '' }) private deliveryDate!: Moment | string;

    private get orderManufactureDate() {
        if (!this.manufactureDate || this.manufactureDate === 'null') {
            return '-';
        }

        return moment(this.manufactureDate).format('DD.MM.YYYY');
    }

    private get orderDeliveryDate() {
        if (!this.deliveryDate || this.deliveryDate === 'null') {
            return '-';
        }

        return moment(this.deliveryDate).format('DD.MM.YYYY');
    }
}
