
import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { UserRightsMixin } from '@/mixins/UserRights';
import Project from '@/models/Project';
import SinglePdfPreview from './SinglePdfPreview.vue';
import Offer from '@/models/Offer';
import InternalOrder from '@/models/InternalOrder';
import { PDFOption } from '@/interfaces/components/projectNew/PDFOption';
import { PDFAttachment } from '@/interfaces/components/projectNew/InternalOrderPDFAttachment';

@Component({
    name: 'PdfListOverview',
    components: {
        SinglePdfPreview,
    },
})
export default class PdfListOverview extends mixins<UserRightsMixin>(UserRightsMixin) {
    @Prop({ required: true }) private project!: Project | null;
    @Prop({ required: true }) private selectedOffer!: Offer | null;

    private get pdfOptions(): PDFOption[] {
        return [
            {
                title: this.$t('Offer'),
                hasPDF: this.hasOfferPDF,
                isCompleted: this.isOfferCompleted,
                key: 'Offer',
            },
            {
                title: this.$t('Order confirmation'),
                hasPDF: this.hasOrderConfirmationPDF,
                isCompleted: this.isOrderCompleted,
                key: 'Order confirmation',
            },
            {
                title: this.$t('Order'),
                hasPDF: this.hasOrderPDF,
                isCompleted: this.isOrderCompleted,
                key: 'Order',
            },
            {
                title: this.$t('Delivery note'),
                hasPDF: this.hasDeliveryNotePDF,
                isCompleted: this.isDeliveryNoteCompleted,
                PDF: this.deliveryNotePDF,
                key: 'Delivery note',
            },
            {
                title: this.$t('Invoice'),
                hasPDF: this.hasInvoicePDF,
                isCompleted: this.isInvoiceCompleted,
                PDF: this.invoicePDF,
                key: 'Invoice',
            },
        ];
    }

    private get deliveryNotePDF(): PDFAttachment | undefined {
        if (this.project?.projectInternalOrders == null) {
            return;
        }

        return this.getPDFData('deliveryNote');
    }

    private get invoicePDF(): PDFAttachment | undefined {
        if (this.project?.projectInternalOrders == null) {
            return;
        }

        return this.getPDFData('invoice');
    }

    private get hasOfferPDF() {
        return !!(this.selectedOffer && this.selectedOffer.pdf);
    }

    private get hasOrderConfirmationPDF() {
        return !!(this.selectedOffer && this.selectedOffer.orderPdf);
    }

    private get hasOrderPDF() {
        return !!(this.selectedOffer && this.selectedOffer.orderPdf);
    }

    private get hasDeliveryNotePDF() {
        return !!this.deliveryNotePDF;
    }

    private get hasInvoicePDF() {
        return !!this.invoicePDF;
    }

    private get isOfferCompleted() {
        return !!this.project && this.selectedOffer !== null && this.project.state !== 'lead';
    }

    private get isOrderCompleted() {
        return this.isOfferCompleted && this.project!.state !== 'offer';
    }

    private get isDeliveryNoteCompleted() {
        return this.isOrderCompleted && this.hasAnyOrderType('deliveryNote');
    }

    private get isInvoiceCompleted() {
        return this.isOrderCompleted && this.hasAnyOrderType('invoice');
    }

    private hasAnyOrderType(orderType: string) {
        if (!this.project?.projectInternalOrders) {
            return false;
        }

        return this.project.projectInternalOrders.some((order: InternalOrder) => order.type === orderType);
    }

    private getPDFData(orderType: string) {
        for (const order of this.project!.projectInternalOrders) {
            if (!order.attachments || order.type !== orderType) {
                continue;
            }

            return this.checkAndReturnVisiblePDFData(order.attachments);
        }
    }

    private checkAndReturnVisiblePDFData(attachments: PDFAttachment[]) {
        for (const attachment of attachments) {
            if (attachment.attachment) {
                return attachment;
            }
        }
    }
}
