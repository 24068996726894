
import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { UserRightsMixin } from '@/mixins/UserRights';
import Project from '@/models/Project';
import moment from 'moment';
import InternalOrder from '@/models/InternalOrder';
import { TableInternalOrder } from '@/interfaces/components/projectNew/TableInternalOrder';

@Component({
    name: 'ProjectInternalOrders',
})
export default class ProjectInternalOrders extends mixins<UserRightsMixin>(UserRightsMixin) {
    @Prop({ default: null }) private project!: Project | null;

    private get columns() {
        return [
            {
                title: this.$t('Internal order'),
                dataIndex: 'order',
                key: 'order',
            },
            {
                title: this.$t('System'),
                dataIndex: 'system',
                key: 'system',
            },
            {
                title: this.$t('Status'),
                dataIndex: 'status',
                key: 'status',
            },
            {
                title: this.$t('Date created'),
                dataIndex: 'dateCreated',
                key: 'dateCreated',
            },
            {
                title: this.$t('Manufacture date'),
                dataIndex: 'manufactureDate',
                key: 'manufactureDate',
            },
            {
                title: this.$t('Delivery date'),
                dataIndex: 'deliveryDate',
                key: 'deliveryDate',
            },
        ];
    }

    private get internalOrders(): TableInternalOrder[] {
        if (!this.project) {
            return [];
        }
        const orders = this.project.projectInternalOrders;

        if (!orders) {
            return [];
        }

        return orders.map((order: InternalOrder) => {
            return {
                key: order.id,
                order: order.orderNumber,
                system: this.$t(order.system),
                status: this.$t(order.orderStatus.code),
                dateCreated: moment(order.createdAt).format('DD.MM.YYYY'),
                manufactureDate: moment(order.manufactureDate).format('DD.MM.YYYY'),
                deliveryDate: moment(order.deliveryDate).format('DD.MM.YYYY'),
            };
        });
    }
}
