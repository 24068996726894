
import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { UserRightsMixin } from '@/mixins/UserRights';
import OfferRepository from '@/repositories/OfferRepository';
import { onDownloadPDF } from '@/helpers/OfferHelper';
import Client from '@/models/Client';
import { getOrderPdfForPreview, downloadOrderPDF } from '@/helpers/OrderHelper';
import i18n from '@/i18n';
// @ts-ignore
import downloadFile from 'downloadjs';
import Offer from '@/models/Offer';
import OrderPdf from '@/models/OrderPdf';
import { PDFOption } from '@/interfaces/components/projectNew/PDFOption';

@Component({
    name: 'SinglePdfPreview',
    components: {},
})
export default class ActiveStatus extends mixins<UserRightsMixin>(UserRightsMixin) {
    @Prop({ default: null }) private pdfOption!: PDFOption;
    @Prop({ default: null }) private client!: Client;
    @Prop({ required: true }) private offer!: Offer;

    private downloadPDF() {
        if (!this.offer) {
            this.$notification.error({
                message: this.$t('Error downloading PDF') as string,
                description: this.$t('Offer not found') as string,
            });
            return;
        }

        switch (this.pdfOption.key) {
            case 'Offer':
                this.downloadOfferPDF();
                break;
            case 'Order confirmation':
                this.downloadOrderConfirmationPDF();
                break;
            case 'Order':
                this.downloadOrderPDF();
                break;
            case 'Delivery note':
            case 'Invoice':
                this.downloadInternalOrderPDF();
        }
    }

    private async downloadOfferPDF() {
        if (!this.pdfOption.hasPDF) {
            return;
        }
        const offer = OfferRepository.getOfferById(this.offer.id);

        try {
            await onDownloadPDF(offer, this.client);
        } catch (e) {
            this.$notification.error({
                message: this.$t('Error downloading PDF') as string,
                description: (e as Error).message,
            });
            return;
        }

        this.$notification.success({
            message: this.$t('Offer PDF successfully downloaded') as string,
            description: '',
        });
    }

    private async downloadOrderConfirmationPDF() {
        if (!this.pdfOption.hasPDF) {
            return;
        }

        try {
            const pdfData = await getOrderPdfForPreview(this.offer.id, i18n.locale);

            downloadFile(pdfData.file.data, `Offer: ${this.offer.id}: Order PDF`);
        } catch (e) {
            this.$notification.error({
                message: this.$t('Error downloading PDF') as string,
                description: (e as Error).message,
            });
            return;
        }

        this.$notification.success({
            message: this.$t('Order confirmation PDF successfully downloaded') as string,
            description: '',
        });
    }

    private async downloadOrderPDF() {
        if (!this.pdfOption.hasPDF) {
            return;
        }
        try {
            await downloadOrderPDF(this.offer, this.client);
        } catch (e) {
            this.$notification.error({
                message: this.$t('Error downloading PDF') as string,
                description: (e as Error).message,
            });
            return;
        }
        this.$notification.success({
            message: this.$t('Order PDF successfully downloaded') as string,
            description: '',
        });
    }

    private async downloadInternalOrderPDF() {
        if (!this.pdfOption.hasPDF || !this.pdfOption.PDF) {
            return;
        }

        try {
            const pdfData = await OrderPdf.getInternalOrderPDF(this.pdfOption.PDF.attachment);

            downloadFile(pdfData.data, this.pdfOption.PDF.displayName);
        } catch (e) {
            this.$notification.error({
                message: this.$t('Error downloading PDF') as string,
                description: (e as Error).message,
            });
            return;
        }

        this.$notification.success({
            message: this.$t(`${this.pdfOption.title} PDF successfully downloaded`) as string,
            description: '',
        });
    }
}
