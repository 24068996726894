
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import Project from '@/models/Project';
import ProjectShellComponent from '@/components/views/NewProjectView/ProjectShellComponent.vue';
import ProjectInternalOrders from '@/components/views/NewProjectView/Order/ProjectInternalOrders.vue';
import PdfListOverview from '@/components/views/NewProjectView/Order/PdfListOverview.vue';
import OrderGeneralInfo from '@/components/views/NewProjectView/Order/OrderGeneralInfo.vue';
import DownloadLeadPDF from '@/components/views/NewProjectView/Lead/DownloadLeadPDF.vue';
import { ProjectTabValues } from '@/enums/components/Project/ProjectTabValues';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import { ProjectStates } from '@/enums/components/Project/ProjectStates';
import { setFirstProjectOfferAsSelectedIfNoneIsSelected } from '@/helpers/NewProject/NewProjectHelper';
import OfferRepository from '@/repositories/OfferRepository';

@Component({
    name: 'ProjectTabOrders',
    components: {
        ProjectShellComponent,
        DownloadLeadPDF,
        ProjectInternalOrders,
        PdfListOverview,
        OrderGeneralInfo,
    },
})
export default class ProjectTabOrders extends Vue {
    @Action('projectLoadingState/updateAreOffersLoading')
    private updateAreOffersLoading!: (newState: boolean) => void;
    @Prop({ required: true }) private projectId!: string;
    @Prop({ default: [] }) private loadedKeys!: Set<ProjectTabValues>;
    @Prop({ required: true }) private project!: Project;
    // @Prop({ required: true }) private selectedOffer!: Offer;
    @Prop({ required: true }) private lockEditing!: boolean;

    private isLoaded: boolean = false;

    public get isProjectLocked() {
        if (this.project == null) {
            return false;
        }
        return this.project.state === ProjectStates.ORDER || this.lockEditing;
    }

    private get selectedOffer() {
        return OfferRepository.getSelectedOffer(this.projectId);
    }

    private async created() {
        if (this.loadedKeys.has(ProjectTabValues.Orders)) {
            return;
        }
        this.$emit('update:isTabLoading', true);

        try {
            setFirstProjectOfferAsSelectedIfNoneIsSelected(this.projectId);

            EventBus.$emit(EventBusEvents.fetchSelectedOfferFromRepository);
            this.$emit('update:loadedKeys', new Set([...this.loadedKeys, ProjectTabValues.Orders]));
        } catch (e) {
            this.$notification.error({
                message: this.$t('Error'),
                description: this.$t('An error occurred while loading the project order data.'),
            });
        } finally {
            this.updateAreOffersLoading(false);
            this.$emit('update:isTabLoading', false);
            this.isLoaded = true;
        }
    }
}
